import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import './EditCategory.css'; // Import the CSS file for styling

const EditCategory = () => {
    const { id } = useParams();
    const [name, setName] = useState('');
    const [image, setImage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(`https://betssonstaging2.sohajewellers.com/api.php?resource=categories&id=${id}`)
            .then(response => {
                setName(response.data.name);
                setImage(response.data.image);
            })
            .catch(error => {
                console.error('There was an error fetching the category!', error);
            });
    }, [id]);

    const handleEditCategory = () => {
        axios.put(`https://betssonstaging2.sohajewellers.com/api.php?resource=categories&id=${id}`, { name, image })
            .then(response => {
                navigate('/categories');
            })
            .catch(error => {
                console.error('There was an error updating the category!', error);
            });
    };

    return (
        <div className="edit-category-container">
            <div className="edit-category-form">
            <h2>Betsson</h2>
                <h1 className="form-title">Edit Category</h1>
                <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Category Name"
                    className="form-input"
                />
                <input
                    type="text"
                    value={image}
                    onChange={(e) => setImage(e.target.value)}
                    placeholder="Image URL"
                    className="form-input"
                />
                <button onClick={handleEditCategory} className="submit-button">Update Category</button>
            </div>
        </div>
    );
};

export default EditCategory;
