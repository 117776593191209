import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './EditArticle.css'; // Import the CSS file for styling

const EditArticle = () => {
    const { id } = useParams();
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [image_url, setImageUrl] = useState('');
    const [questions, setQuestions] = useState('');
    const [answers, setAnswers] = useState('');
    const [category_id, setCategoryId] = useState('');
    const [categories, setCategories] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(`https://betssonstaging2.sohajewellers.com/api.php?resource=articles&id=${id}`)
            .then(response => {
                const article = response.data;
                setTitle(article.title);
                setContent(article.content);
                setImageUrl(article.image_url);
                setQuestions(article.questions);
                setAnswers(article.answers);
                setCategoryId(article.category_id);
            })
            .catch(error => {
                console.error('There was an error fetching the article!', error);
            });

        // Fetch categories for the dropdown
        axios.get('https://betssonstaging2.sohajewellers.com/api.php?resource=categories')
            .then(response => {
                setCategories(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the categories!', error);
            });
    }, [id]);

    const handleEditArticle = () => {
        axios.put(`https://betssonstaging2.sohajewellers.com/api.php?resource=articles&id=${id}`, 
        { title, content, image_url, questions, answers, category_id: parseInt(category_id, 10) })
            .then(response => {
                navigate('/articles');
            })
            .catch(error => {
                console.error('There was an error updating the article!', error);
            });
    };

    return (
        <div className="edit-article-container">
            <div className="edit-article-form">
            <h1 className="betsson">Betsson</h1>
                <h2>Edit Article</h2>
                
                <input
                    type="text"
                    value={questions}
                    onChange={(e) => setQuestions(e.target.value)}
                    placeholder="Questions"
                    className="input-field"
                />
                
                <CKEditor
                    editor={ClassicEditor}
                    data={answers}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        setAnswers(data);
                    }}
                />
                
                <select
                    value={category_id}
                    onChange={(e) => setCategoryId(e.target.value)}
                    className="input-field"
                    required
                >
                    <option value="">Select Category</option>
                    {categories.map(category => (
                        <option key={category.id} value={category.id}>
                            {category.cat_name}
                        </option>
                    ))}
                </select>
                
                <button onClick={handleEditArticle} className="submit-button">Update Article</button>
            </div>
        </div>
    );
};

export default EditArticle;
