import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleLogout = async () => {
      try {
        const response = await axios.post('https://betssonstaging2.sohajewellers.com/auth.php?action=logout');

        if (response.data.status === 'success') {
          sessionStorage.removeItem('user'); // Clear user session
          navigate('/login'); // Redirect to login page after logout
        } else {
          console.error('Logout failed:', response.data.message);
        }
      } catch (error) {
        console.error('Logout error:', error.message);
      }
    };

    handleLogout(); // Trigger the logout process on component mount
  }, [navigate]);

  return null; // No need to render anything
};

export default Logout;
