import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is logged in by checking session storage or a similar method
    const user = sessionStorage.getItem('user');
    if (user) {
      setIsLoggedIn(true);
    }
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

 

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link to="/" className="navbar-logo">Betsson</Link>
        <div className="menu-icon" onClick={toggleMenu}>
          {isMenuOpen ? (
            <span className="icon close">X</span>
          ) : (
            <span className="icon">&#9776;</span> // Hamburger icon
          )}
        </div>
        <ul className={`nav-menu ${isMenuOpen ? 'active' : ''}`}>
          <li className="nav-item">
            <Link to="/categories" className="nav-links" onClick={toggleMenu}>Categories</Link>
          </li>
          <li className="nav-item">
            <Link to="/articles" className="nav-links" onClick={toggleMenu}>Articles</Link>
          </li>
          <li className="nav-item">
            <Link to="/add-category" className="nav-links" onClick={toggleMenu}>Add Category</Link>
          </li>
          <li className="nav-item">
            <Link to="/add-article" className="nav-links" onClick={toggleMenu}>Add Article</Link>
          </li>
          <li className="nav-item">
            <Link to="/Logout" className="nav-links" id="Logout" onClick={toggleMenu}>Logout</Link>
          </li>
          
         
          
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
