import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Signup.css'; // Import the CSS for styling

const Signup = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://betssonstaging2.sohajewellers.com/auth.php?action=signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: `username=${encodeURIComponent(username)}&email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}&role=${encodeURIComponent(role)}`
      });

      if (!response.ok) {
        throw new Error(` ${response.status}`);
      }

      const data = await response.json();

      if (data.status === 'success') {
        navigate('/login');  // Successful signup, redirect to login page
      } else {
        setError(data.message);
      }
    } catch (error) {
      console.error('Signup error:', error); // Log error for debugging
      setError('An error occurred during signup. Please try again.');
    }
  };

  return (
    <div className="signup-container">
       <div className="lock-animation">
        <div className="lock">
          <div className="keyhole"></div>
        </div>
        <div className="lock-animation-overlay"></div>
      </div>
      <form className="signup-form" onSubmit={handleSignup}>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
          required
          className="signup-input"
        />
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
          className="signup-input"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          required
          className="signup-input"
        />
        <input
          type="text"
          value={role}
          onChange={(e) => setRole(e.target.value)}
          placeholder="Role"
          required
          className="signup-input"
        />
        <button type="submit" className="signup-button">Signup</button>
        <Link to="/login" className="signup-link">Already have an account? Log In</Link>
      </form>
      {error && <p className="signup-error">{error}</p>}
    </div>
  );
};

export default Signup;
