import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CategoryList from './components/CategoryList';
import AddCategory from './components/AddCategory';
import EditCategory from './components/EditCategory';
import ArticleList from './components/ArticleList';
import AddArticle from './components/AddArticle';
import EditArticle from './components/EditArticle';
import WelcomePage from './components/WelcomePage';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Login from './components/Login';
import Signup from './components/Signup';
import Logout from './components/Logout';
import ProtectedRoute from './components/ProtectedRoute';

const App = () => {
  return (
    <>
      
      <Routes>
        <Route path="/Login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/logout" element={<Logout />} />

        {/* Protected Routes */}
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Navbar />
              <WelcomePage />
              <Footer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/categories"
          element={
            <ProtectedRoute>
              <Navbar/>
              <CategoryList />
              <Footer/>
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-category"
          element={
            <ProtectedRoute>
              <Navbar/>
              <AddCategory />
              <Footer/>
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-category/:id"
          element={
            <ProtectedRoute>
              <Navbar/>
              <EditCategory />
              <Footer/>
            </ProtectedRoute>
          }
        />
        <Route
          path="/articles"
          element={
            <ProtectedRoute>
              <Navbar/>
              <ArticleList />
              <Footer/>
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-article"
          element={
            <ProtectedRoute>
              <Navbar/>
              <AddArticle />
              <Footer/>
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-article/:id"
          element={
            <ProtectedRoute>
              <Navbar/>
              <EditArticle />
              <Footer/>
            </ProtectedRoute>
          }
        />
      </Routes>
      
    </>
  );
};

export default App;
