import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './WelcomePage.css'; // Import the CSS for styling

const WelcomePage = () => {
  const [loading, setLoading] = useState(true);
  const [displayText, setDisplayText] = useState(''); // Initialized as an empty string
  const text = 'Betsson';

  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      if (index < text.length) {
        setDisplayText((prev) => prev + text[index]);
        index += 1;
      } else {
        clearInterval(interval);
      }
    }, 300); // Adjust the speed of the animation here (in milliseconds)

    // Hide the loading screen after the animation completes
    const timeout = setTimeout(() => {
      setLoading(false);
    }, (text.length * 300) + 500); // Adjust duration as needed

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [text]); // Ensure this runs once on mount

  return (
    <>
      {loading ? (
        <div className="loading-screen">
          <h1 className="loading-text">{displayText}</h1>
        </div>
      ) : (
        <div className="welcome-container">
          <h1 className="welcome-title">Welcome to Betsson Support Center Database</h1>
          <p className="welcome-description">
            Empowering you to efficiently manage categories and articles for seamless customer support.
          </p>
          <div className="button-container">
            <Link to="/categories" className="btn btn-add-category">
              Manage Categories
            </Link>
            <Link to="/articles" className="btn btn-add-article">
              Manage Articles
            </Link>
            
          </div>
        </div>
      )}
    </>
  );
  
};

export default WelcomePage;
