import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './CategoryList.css'; // Import the CSS file

const CategoryList = () => {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        axios.get('https://betssonstaging2.sohajewellers.com/api.php?resource=categories')
            .then(response => {
                setCategories(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the categories!', error);
            });
    }, []);

    const handleDelete = (id) => {
        axios.delete(`https://betssonstaging2.sohajewellers.com/api.php?resource=categories&id=${id}`)
            .then(response => {
                setCategories(categories.filter(category => category.id !== id));
            })
            .catch(error => {
                console.error('There was an error deleting the category!', error);
            });
    };

    return (
        <div className="category-list-container">
            <h1 className="category-list-heading">Categories</h1>
           <Link to="/add-category" className="add-category-link">Add New Category</Link>
            <ul className="category-list">
                {categories.map(category => (
                    <li key={category.id} className="category-item">
                        <span className="category-name">{category.name}</span>
                        <div className="category-actions">
                            <Link to={`/edit-category/${category.id}`} className="edit-button">Edit</Link>
                            <button onClick={() => handleDelete(category.id)} className="delete-button">Delete</button>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default CategoryList;
