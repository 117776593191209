import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './ArticleList.css'; // Import the CSS file for styling

const ArticleList = () => {
    const [articles, setArticles] = useState([]);
    const [categories, setCategories] = useState([]);
    const [activeCategory, setActiveCategory] = useState(null);
    const [articleCount, setArticleCount] = useState({});

    useEffect(() => {
        // Fetch categories
        axios.get('https://betssonstaging2.sohajewellers.com/api.php?resource=categories')
            .then(response => {
                setCategories(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the categories!', error);
            });

        // Fetch articles
        axios.get('https://betssonstaging2.sohajewellers.com/api.php?resource=articles')
            .then(response => {
                const fetchedArticles = response.data;
                setArticles(fetchedArticles);

                // Count articles per category
                const count = fetchedArticles.reduce((acc, article) => {
                    acc[article.category_id] = (acc[article.category_id] || 0) + 1;
                    return acc;
                }, {});

                setArticleCount(count);
            })
            .catch(error => {
                console.error('There was an error fetching the articles!', error);
            });
    }, []);

    const handleDelete = (id) => {
        axios.delete(`https://betssonstaging2.sohajewellers.com/api.php?resource=articles&id=${id}`)
            .then(response => {
                setArticles(articles.filter(article => article.id !== id));
            })
            .catch(error => {
                console.error('There was an error deleting the article!', error);
            });
    };

    const toggleCategory = (categoryId) => {
        if (activeCategory === categoryId) {
            setActiveCategory(null);
        } else {
            setActiveCategory(categoryId);
        }
    };

    return (
        <div className="article-list-container">
            <h1 className="page-title">Articles by Category</h1>
            <Link to="/add-article" className="btn btn-add-articles">Add New Article</Link>

            {categories.length > 0 ? (
                categories.map(category => (
                    <div key={category.id} className="category-section">
                        <h2 
                            className="category-title" 
                            onClick={() => toggleCategory(category.id)}
                        >
                            {category.name} ({articleCount[category.id] || 0})
                            <span className={`toggle-icon ${activeCategory === category.id ? 'open' : 'closed'}`}></span>
                        </h2>
                        {activeCategory === category.id && (
                            <ul className="article-list">
                                {articles
                                    .filter(article => article.category_id === category.id)
                                    .map(article => (
                                        <li key={article.id} className="article-item">
                                            <div className="article-content">
                                                <p className="article-question">{article.questions}</p>
                                            </div>
                                            <div className="article-actions">
                                                <Link to={`/edit-article/${article.id}`} className="btn btn-edit-article">Edit</Link>
                                                <button 
                                                    onClick={() => handleDelete(article.id)}
                                                    className="btn btn-delete-article"
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                        </li>
                                    ))}
                            </ul>
                        )}
                    </div>
                ))
            ) : (
                <p>No categories found or no articles available.</p>
            )}
        </div>
    );
};

export default ArticleList;
