import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './AddArticle.css'; // Import the CSS file for styling


const AddArticle = () => {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [image_url, setImageUrl] = useState('');
    const [questions, setQuestions] = useState('');
    const [answers, setAnswers] = useState('');
    const [category_id, setCategoryId] = useState('');
    const [categories, setCategories] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        axios.get('https://betssonstaging2.sohajewellers.com/api.php?resource=categories')
            .then(response => {
                setCategories(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the categories!', error);
            });
    }, []);

    const handleAddArticle = () => {
        if (!category_id) {
            alert('Please select a category.');
            return;
        }

        axios.post(
            'https://betssonstaging2.sohajewellers.com/api.php?resource=articles', 
            { title, content, image_url, questions, answers, category_id: parseInt(category_id, 10) },
            { headers: { 'Content-Type': 'application/json' } }
        )
        .then(response => {
            console.log('Article added successfully:', response.data);
            navigate('/articles');
        })
        .catch(error => {
            console.error('There was an error adding the article!', error);
        });
    };

    return (
        <div className="add-article-container">
            <div className="add-article-form">
                <h2>Betsson</h2>
                <h1>Add New Article</h1>
                
                <input
                    type="text"
                    value={questions}
                    onChange={(e) => setQuestions(e.target.value)}
                    placeholder="Questions"
                    className="input-field"
                />
                <CKEditor
                    editor={ClassicEditor}
                    data={answers}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        setAnswers(data);
                    }}
                />
                <select
                    value={category_id}
                    onChange={(e) => setCategoryId(e.target.value)}
                    className="input-field"
                    required
                >
                    <option value="" className="opt">Select Category</option>
                    {categories.map(category => (
                        <option  key={category.id} value={category.id}>
                            {category.name}
                        </option>
                    ))}
                </select>
                <button onClick={handleAddArticle} className="submit-button">Add Article</button>
            </div>
        </div>
    );
};

export default AddArticle;
