import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Login.css'; // Import the CSS for styling

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://betssonstaging2.sohajewellers.com/auth.php?action=login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: `email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      if (data.status === 'success') {
        sessionStorage.setItem('user', JSON.stringify(data.user)); // Store user in session
        navigate('/');  // Successful login, redirect to homepage
      } else {
        setError(data.message);
      }
    } catch (error) {
      console.error('Login error:', error); // Log error for debugging
      setError('An error occurred during login. Please try again.');
    }
  };

  return (
    <div className="login-container">
      <div className="lock-animation">
        <div className="lock">
          <div className="keyhole"></div>
        </div>
        <div className="lock-animation-overlay"></div>
      </div>
      
      <form className="login-form" onSubmit={handleLogin}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
          className="login-input"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          required
          className="login-input"
        />
        <button type="submit" className="login-button">Login</button>
        <Link to="/signup" className="signup-link">Don't have an account? Create one</Link>
      </form>
      {error && <p className="login-error">{error}</p>}
    </div>
  );
};

export default Login;
